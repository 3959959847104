import { createContext, useContext } from 'react';

type LocaleContext = {
	locales: string[];
};

type LocaleContextProviderProps = {
	locales: string[];
	children: React.ReactNode;
};

const Context = createContext<LocaleContext | null>(null);

export function LocaleContextProvider({ locales, children }: LocaleContextProviderProps) {
	const value = { locales };
	return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useLocales() {
	const localeContext = useContext(Context);

	if (!localeContext) {
		throw new Error('Please wrap your application in a LocaleContextProvider.');
	}

	return localeContext.locales;
}
